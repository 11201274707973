<template>
  <v-container>
    <h4 class="text-h6 mb-3">{{ title }}</h4>
    <v-form v-model="formValid">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            label="Titel"
            v-model="event.title"
            required
            tabindex="1"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col cols="6">
          <date-text-field
            label="Startdatum"
            v-model="event.start_date"
            @blur="setEndDate()"
            required
            :rules="[rules.required]"
            hint="Zum Beispiel 23.5.17"
            tabindex="2"
          />
        </v-col>
        <v-col cols="6">
          <date-text-field
            label="Enddatum"
            v-model="event.end_date"
            :rules="[dateIsAfterStartDate]"
            hint="Zum Beispiel 24.5.17"
            tabindex="3"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Tourengruppe"
            required
            :items="organisations"
            item-value="code"
            item-text="name"
            v-model="event.organisation"
            tabindex="4"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Hauptleiter"
            :items="persons"
            v-model="event.main_guide"
            required
            tabindex="5"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Teilnahme möglich für"
            :items="masked_organisations"
            item-value="code"
            item-text="name"
            v-model="event.allowed_organisations"
            multiple
            deletable-chips
            chips
            tabindex="6"
          />
        </v-col>

        <v-col cols="12" sm="6" style="align-self: flex-end">
          <v-text-field
            label="Max. Anzahl Teilnehmer"
            type="number"
            v-model.number="event.max_participants"
            tabindex="7"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Tourart"
            :items="event_kinds"
            v-model="event.kind"
            tabindex="8"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Schwierigkeit"
            v-model="event.difficulty"
            tabindex="9"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Koordinaten"
            v-model="event.location"
            tabindex="10"
            hint="Lat/Lon Format, z.B: 46.94342, 9.12389"
            :rules="[rules.latLng]"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Link Tourenplanung"
            v-model="event.external_planing"
            tabindex="11"
            type="url"
            hint="Link zur externen Tourenplanung (Whiterisk / ...)"
          />
        </v-col>

        <v-col cols="12" v-if="!hasRole('manager')">
          <div class="grey--text">
            Hinweis: Die Tourenfreigabe und das Tourenblatt kann nur von
            Tourenchefs geändert werden.
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-checkbox
            label="Tourenrückmeldung erforderlich"
            v-model="event.needs_feedback"
            :disables="!hasRole('manager')"
            tabindex="12"
          />
          <v-checkbox
            label="Tour freigeben"
            v-model="event.published"
            :disabled="!hasRole('manager')"
            tabindex="13"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <file-drag-drop
            :value="event.upload"
            :disabled="!hasRole('manager')"
            tabindex="14"
            label="Tourenblatt"
            accept=".pdf"
            @formData="onNewUpload"
            @remove="confirmRemovePDFDialog = true"
          />
        </v-col>

        <v-col cols="12" v-if="event.id">
          <v-btn :to="{ name: 'EventView', params: { id: event.id } }"
            >Teilnehmerliste
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="success"
            class="mr-2"
            @click="
              () => {
                saveAndNew = false;
                save();
              }
            "
            tabindex="15"
            :disabled="!formValid"
            >Speichern</v-btn
          >
          <v-btn
            color="success"
            @click="
              () => {
                saveAndNew = true;
                save();
              }
            "
            tabindex="16"
            :disabled="!formValid"
            class="hidden-sm-and-down mr-2"
            >Speichern &amp; Neu</v-btn
          >
          <v-btn
            color="error"
            @click="confirmDeleteDialog = true"
            v-if="hasRole('manager') && event.id"
            class="hidden-sm-and-down mr-2"
            >Löschen</v-btn
          >
          <v-btn @click="$router.go(-1)" style="float: right" tabindex="15"
            >Abbrechen</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <confirm-dialog
      v-model="confirmDeleteDialog"
      title="Tour löschen"
      persistent
      @confirm="deleteEvent"
      max-width="300"
      confirm-color="error"
      confirm-text="Tour löschen"
    >
      Die Tour <b>{{ event.title }}</b> wird gelöscht. Alle Teilnehmer, Leiter
      und Gäste werden von der Tour entfernt. Diese Aktion kann nicht rückgangig
      gemacht werden!
    </confirm-dialog>

    <confirm-dialog
      v-model="confirmRemovePDFDialog"
      title="Tourenblatt löschen"
      persistent
      @confirm="deletePDF"
      :max-width="300"
      confirm-color="error"
      confirm-text="Löschen"
    >
      Das Tourenblatt für <b>{{ event.title }}</b> wird gelöscht. Diese Aktion
      kann nicht rückgängig gemacht werden!
    </confirm-dialog>

    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import { mapState } from 'pinia'

import { handleApiError, createNotification } from '@/utils.js'
import { DateOnly, dateDMYtoDDMMYYYY, dateIsValid } from '@/utils/dates.js'

import DateTextField from '@/components/fields/DateTextField'
import FileDragDrop from '@/components/fields/FileDragDrop'
import ConfirmDialog from '@/components/ConfirmDialog'
import Confirm from '@/components/Confirm'
import { usePersonStore } from '@/stores/person'
import { useEventStore } from '@/stores/event'
import { useAuthStore } from '@/stores/auth'

const EMPTY_EVENT = {
  // id: undefined,  // Do not provide a id, new events should not contain a id!
  title: '',
  start_date: '',
  end_date: '',
  organisation: null,
  allowed_organisations: [],
  main_guide: null,
  guides: [],
  kind: null,
  difficulty: '',
  published: false,
  report: '',
  max_participants: null,
  location: null,
  needs_feedback: true,
}

export default {
  name: 'EventEdit',
  components: { FileDragDrop, DateTextField, ConfirmDialog, Confirm },
  props: {},
  data() {
    return {
      formValid: true,
      download: '',
      downloadFormData: null,
      saveAndNew: false,
      isNewEvent: true,
      event: clone(EMPTY_EVENT),
      confirmDeleteDialog: false,
      confirmRemovePDFDialog: false,
      rules: {
        required: (value) => !!value || 'Dieses Feld ist erforderlich',
        latLng: (value) => {
          const pattern = /([+-]?\d{1,3}\.\d{1,8}),[\s]?([+-]?\d{1,3}\.\d{1,8})/
          return !value || pattern.test(value) || 'Ungültige Koordinaten'
        },
      },
    }
  },
  computed: {
    title() {
      return this.isNewEvent ? 'Neue Tour erstellen' : `Tour ${this.event.title} bearbeiten`
    },
    persons() {
      return this.personList.map((obj) => { return { value: obj.id, text: obj.name } })
    },
    uploadHint() {
      if (this.event.upload) {
        return 'Wird ein neues Tourenblatt ausgewählt wird das aktuelle überschrieben.'
      } else {
        return 'Noch kein Tourenblatt vorhanden.'
      }
    },
    masked_organisations() {
      return (this.organisations || []).map(e => {
        return {
          ...e,
          disabled: e.code === this.event.organisation,
        }
      })
    },
    ...mapState(useAuthStore, ['hasRole']),
    ...mapState(usePersonStore, ['personList']),
    ...mapState(useEventStore, {
      organisations: state => state.meta.organisations,
      event_kinds: state => state.meta.kinds,
    }),
  },
  watch: {
    $route: 'fetchEvent',
  },
  created() {
    this.fetchEvent()
  },
  methods: {
    async save() {
      const daysUntilStart = DateOnly.fromDate(new Date()).daysUntil(this.event.start_date)
      const daysUntilEnd = DateOnly.fromDate(new Date()).daysUntil(this.event.end_date)

      // Check if the event is in the past. Ask the user for a confirmation if the event is in the past.
      if (this.isNewEvent && (daysUntilStart < 0 || daysUntilEnd < 0)) {
        const ans = await this.$refs.confirm.open(
          'Datum in Vergangenheit',
          'Das gewählte Datum liegt in der Vergangenheit! Soll die Tour trotzdem gespeichert werden?',
          { color: 'red lighten-1' },
        )
        if (!ans) {
          // User aborted. Do not proceed.
          return
        }
      }

      const eventDuration = DateOnly.fromString(this.event.start_date).daysUntil(this.event.end_date) + 1
      if (eventDuration > 20) {
        const ans = await this.$refs.confirm.open(
          'Grosser Datumsunterschied',
          `Die Tour startet am ${this.event.start_date} und dauert bis zum ${this.event.end_date}. ` +
          'Sind diese Daten korrekt?',
          { color: 'red lighten-1' },
        )
        if (!ans) {
          // User aborted. Do not proceed.
          return
        }
      }

      let promise = null

      // Save the event
      if (this.isNewEvent) {
        promise = useEventStore().createEvent(this.event)
      } else {
        promise = useEventStore().updateEvent(this.event)
      }

      // Upload new event detail sheet if one was selected
      if (this.downloadFormData) {
        promise = promise.then((event) => {
          return useEventStore().updateEventSheet({ event: this.event, form: this.downloadFormData })
        })
      }

      promise.then((event) => {
        // Clone the event for editing
        this.event = cloneDeep(event)
        createNotification('Tour gespeichert', 'success')
        if (this.saveAndNew) {
          this.event = clone(EMPTY_EVENT)
          this.$router.push({ name: 'EventEdit', params: { id: 'new' } })
          this.saveAndNew = false
          return
        }
        if (this.isNewEvent) {
          this.$router.push({ name: 'EventEdit', params: { id: this.event.id } })
        }
      }).catch(error => {
        handleApiError(error, 'Fehler beim speichern')
      })
    },
    setEndDate() {
      const date = this.event.end_date || this.event.start_date
      // Blank the field for a tick and set the new value. This triggers the validation of the end_date.
      this.$set(this.event, 'end_date', undefined)
      this.$nextTick(() => {
        this.$set(this.event, 'end_date', date)
      })
    },
    onNewUpload(data) {
      this.downloadFormData = data
      this.save()
    },
    deleteEvent() {
      useEventStore().deleteEvent(this.event)
        .then(event => {
          this.$router.push({ name: 'Events', params: { year: event.start_date.substr(0, 4) } })
        })
    },
    deletePDF() {
      useEventStore().deletePDF(this.event)
        .then(event => {
          this.event = cloneDeep(event)
          createNotification('Tourenblatt gelöscht.', 'success')
        })
    },
    fetchEvent() {
      const id = this.$route.params.id
      if (this.$route.name !== 'EventEdit') { return }
      if (id === undefined) { return }
      if (id === 'new') {
        this.isNewEvent = true
        this.event = cloneDeep(EMPTY_EVENT)
      } else {
        return useEventStore().getEvent(id)
          .then(event => {
            this.isNewEvent = false
            this.event = cloneDeep(event)
            this.event.allowed_organisations = this.event.allowed_organisations.filter(o => o !== this.event.organisation)
          })
      }
    },
    dateIsAfterStartDate(value) {
      if (!value) { return true }
      if (!this.event.start_date) { return true }
      if (!dateIsValid(this.event.start_date)) { return true }
      const d1 = DateOnly.fromString(this.event.start_date)
      const d2 = DateOnly.fromString(dateDMYtoDDMMYYYY(value))
      const dayDiff = d1.daysUntil(d2)
      return dayDiff >= 0 || 'Enddatum vor Startdatum'
    },
  },
}
</script>
